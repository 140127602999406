import React from 'react';
import { BrowserRouter as Router, Routes, Route} from 'react-router-dom';
import { Provider } from 'react-redux';
import store from './redux/store';
import { RootState } from './redux/store';


import { QRCode } from 'react-qrcode-logo';
import Img from './20230830_122109.jpg';

import Glavna from './component/glavna';
// import OdabirVrsteQR from './component/odabir_vrste_qr';
// import WiFiKreiranjeQR from './component/popunjavanje/wi_fi_qr';



function App() {

//   //deo za wifi
//   const wifiData = {
//     ssid: 'Ginder',
//     password: 'suzanafilip',
//     encryption: 'WPA',
//   };
//   const wifiConfigURI = `WIFI:T:${wifiData.encryption};S:${wifiData.ssid};P:${wifiData.password};;`;
//   //deo za wifi

//   //deo za sms
//   const smsData = {
//     phoneNumber: '+381629626045', // Broj telefona primaoca
//     message: 'Tekst SMS poruke proba', // Tekst SMS poruke
//   };
//   const smsUri = `sms:${smsData.phoneNumber}?body=${encodeURIComponent(smsData.message)}`;
//   //deo za sms


//   //deo za email
//   const emailData = {
//     email: 'snimanjeizvazduha1@gmail.com', // E-mail adresa primaoca
//     subject: 'Naslov e-maila', // Naslov e-maila
//     body: 'Tekst e-maila', // Tekst e-maila
//   };
//   const emailUri = `mailto:${emailData.email}?subject=${encodeURIComponent(emailData.subject)}&body=${encodeURIComponent(emailData.body)}`;
//   //deo za email


//   //deo za vizit kartu
//   const vCardData = {
//     firstName: 'John',
//     lastName: 'Doe',
//     organization: 'Company XYZ',
//     phone: '123456789',
//     email: 'john.doe@example.com',
//   };
//   const vCardText = `BEGIN:VCARD
// VERSION:3.0
// FN:${vCardData.firstName} ${vCardData.lastName}
// ORG:${vCardData.organization}
// TEL:${vCardData.phone}
// EMAIL:${vCardData.email}
// END:VCARD`;
//   //deo za vizit kartu



//   //deo ka ocenjivanju na googlu
//   const placeId = 'ChIJIb7A9-53WkcRiz1H3gHqsfk';
//   const googleReviewUrl = `https://search.google.com/local/writereview?placeid=${placeId}`;
//   //https://developers.google.com/maps/documentation/javascript/examples/places-placeid-finder    ovde nalazimo id ka mestu na mapi
//   //deo ka ocenjivanju na googlu


  

//   const websiteUrl = 'https://online-rezervacije.rs/';


  return (

      <Provider store={store}>
        <Router>
          <Routes>
            <Route path="/" element={<Glavna/>} /> 
            {/* <Route path="/wifi" element={<WiFiKreiranjeQR/>} />  */}
            
            {/* <Route path="/magacin" element={<ProtectedRoute component={Magacin}/>} /> */}
          </Routes>
        </Router>
      </Provider>



    // <div>
    //   <h2>Wi-Fi QR kod sa logom</h2>
    //   <QRCode 
    //       value={wifiConfigURI}  
    //       logoImage={Img} 
    //       logoPadding={7} 
    //       size={300} 
    //       logoWidth={150} 
    //       logoHeight={100}
    //       //removeQrCodeBehindLogo={true}
    //       eyeRadius={[
    //         20,  // top/left eye
    //         20, // top/right eye
    //         20,  // bottom/left eye
    //       ]}
    //       // eyeColor={[
    //       //   'red',  // top/left eye
    //       //   'blue', // top/right eye
    //       //   '#ffc0cb',  // bottom/left eye
    //       // ]}
    //       //logoPaddingStyle={'square'} //circle
    //       quietZone={50}
    //       //enableCORS={false}
    //       ecLevel={'Q'} // L | M | Q | H
    //   />

    //   <br/>
    //   <h2>QR kod ka web sajtu</h2>
    //   <QRCode 
    //     value={websiteUrl}
    //     size={300}
    //     quietZone={50}
    //   />


    //   <br/>
    //   <h2>QR kod ka sms poruci</h2>
    //   <QRCode 
    //     value={smsUri} 
    //     size={300}
    //     quietZone={50}
    //   />


    //   <br/>
    //   <h2>QR kod ka email-u</h2>
    //   <QRCode 
    //     value={emailUri} 
    //     size={300}
    //     quietZone={50}
    //   />

    //   <br/>
    //   <h2>QR kod ka vizit karti</h2>
    //   <QRCode 
    //     value={vCardText} 
    //     size={300}
    //     quietZone={50}
    //   />

    //   <br/>
    //   <h2>QR kod ka ocenjivanju mesta na google mapi</h2>
    //   <QRCode 
    //     value={googleReviewUrl} 
    //     size={300}
    //     quietZone={50}
    //   />
    // </div>
  );
}

export default App;
