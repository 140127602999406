import React, { useEffect, useState } from 'react';

import { Button, Box, TextField } from '@mui/material';


import { useDispatch, useSelector } from 'react-redux';
import { setQrCodeString } from '../../redux/stranice/odabir_vrste_qr';




export const SmsForm = (props:any) => {

  const dispatch = useDispatch();
  const [webUrl, setWebUrl] = useState('');
  const handleChangeWebURL = (event:any) => {setWebUrl(event.target.value);};

  const generisi = () => { 

    if(webUrl == ''){
      alert('Web URL je obavezan podatak');
    }else{
       
        const webConfigURI = webUrl;
        
      dispatch(setQrCodeString(webConfigURI)); 

    }
      
  }


  return(
    <>
            

            <Box 
              style={{padding:"20px"}} 
              maxWidth="xl"
              sx={{
                display: 'flex',
                justifyContent: 'center',
                alignItems: 'center',
                flexDirection: 'column',
                margin:'auto'
              }}
            >

            <TextField
                size="small"
                fullWidth
                onChange={handleChangeWebURL}
                label="Tel. Broj"
                variant="outlined"
                value={webUrl}
            />

            <br/>

            <TextField
                size="small"
                fullWidth
                onChange={handleChangeWebURL}
                label="Poruka"
                variant="outlined"
                value={webUrl}
            />
            <br/>
            <Button  color="success" variant="contained"  onClick={() => generisi()}>Generiši za SMS</Button>


            
            </Box>


    
  </>
  
  )
}



export default SmsForm;