import React, { useEffect, useState } from 'react';

import { Button, Box, TextField } from '@mui/material';


import { useDispatch, useSelector } from 'react-redux';
import { setQrCodeString } from '../../redux/stranice/odabir_vrste_qr';




export const VizitKartaForm = (props:any) => {

  const dispatch = useDispatch();
  const [ime, setIme] = useState('');
  const [prezime, setPrezime] = useState('');
  const [firma, setFirma] = useState('');
  const [telefon, setTelefon] = useState('');
  const [email, setEmail] = useState('');
  const handleChangeIme = (event:any) => {setIme(event.target.value);};
  const handleChangePrezime = (event:any) => {setPrezime(event.target.value);};
  const handleChangeFirma = (event:any) => {setFirma(event.target.value);};
  const handleChangeTelefon = (event:any) => {setTelefon(event.target.value);};
  const handleChangeEmail = (event:any) => {setEmail(event.target.value);};

  const generisi = () => { 

    if(ime == ''){
      alert('Web URL je obavezan podatak');
    }else{
       
        

        const webConfigURI = `BEGIN:VCARD
        VERSION:3.0
        FN:${ime} ${prezime}
        ORG:${firma}
        TEL:${telefon}
        EMAIL:${email}
        END:VCARD`;

        
      dispatch(setQrCodeString(webConfigURI)); 

    }
      
  }


  return(
    <>
            

            <Box 
              style={{padding:"20px"}} 
              maxWidth="xl"
              sx={{
                display: 'flex',
                justifyContent: 'center',
                alignItems: 'center',
                flexDirection: 'column',
                margin:'auto'
              }}
            >

            <TextField
                size="small"
                fullWidth
                onChange={handleChangeIme}
                label="Ime"
                variant="outlined"
                value={ime}
            />

            <br/>

            <TextField
                size="small"
                fullWidth
                onChange={handleChangePrezime}
                label="Prezime"
                variant="outlined"
                value={prezime}
            />
            <br/>
            <TextField
                size="small"
                fullWidth
                onChange={handleChangeFirma}
                label="Firma"
                variant="outlined"
                value={firma}
            />
            <br/>
            <TextField
                size="small"
                fullWidth
                onChange={handleChangeTelefon}
                label="Telefon"
                variant="outlined"
                value={telefon}
            />
            <br/>
            <TextField
                size="small"
                fullWidth
                onChange={handleChangeEmail}
                label="Email"
                variant="outlined"
                value={email}
            />
            <br/>
            <Button  color="success" variant="contained"  onClick={() => generisi()}>Generiši za Vizit kartu</Button>


            
            </Box>


    
  </>
  
  )
}



export default VizitKartaForm;