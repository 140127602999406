import { createSlice, PayloadAction } from '@reduxjs/toolkit';


interface odabirVrste {
    stranica: number;
    formaStranica: number;
    vrastaQRKOda: number;
    radijus: number;
    color: string;
    colorBackground: string;
    slikaLogo: string;
    style: number;
    opisQrCode: string;
    qrCodeString: string;
}

const initialState: odabirVrste = {
  stranica: 1,
  formaStranica: 1,
  vrastaQRKOda: 1,   
  radijus: 0,
  color: 'rgb(0, 0, 0)', 
  colorBackground: 'rgb(255, 255, 255)',
  slikaLogo: '../../slike/20230611_163309.jpg',   
  //slikaLogo: '/static/media/20230611_163309.32612c5c0ba10109bebf.jpg' ,
  style: 0,
  opisQrCode: 'SKENIRAJ ME',
  qrCodeString: ''
};

const odabirVrste = createSlice({
  name: 'odabirVrste',
  initialState,
  reducers: {
    setStranica: (state, action: PayloadAction<number>) => {
      state.stranica = action.payload;
    },
    setFormaStranica: (state, action: PayloadAction<number>) => {
      state.formaStranica = action.payload;
    },
    setVrastaQRKOda: (state, action: PayloadAction<number>) => {
      state.vrastaQRKOda = action.payload;
    },
    setRadijus: (state, action: PayloadAction<number>) => {
      state.radijus = action.payload;
    },
    setColor: (state, action: PayloadAction<string>) => {
      state.color = action.payload;
    },
    setColorBackground: (state, action: PayloadAction<string>) => {
      state.colorBackground = action.payload;
    },
    setSlikaLogo: (state, action: PayloadAction<string>) => {
      state.slikaLogo = action.payload;
    },
    setStyle: (state, action: PayloadAction<number>) => {
      state.style = action.payload;
    },
    setOpisQrCode: (state, action: PayloadAction<string>) => {
      state.opisQrCode = action.payload;
    },
    setQrCodeString: (state, action: PayloadAction<string>) => {
      state.qrCodeString = action.payload;
    },
  }
});

export const { setStranica, setFormaStranica, setVrastaQRKOda, setRadijus, setColor, setColorBackground, setSlikaLogo, setStyle, setOpisQrCode, setQrCodeString } = odabirVrste.actions;
export const odabirVrsteQRReducer = odabirVrste.reducer;
