import React, { useEffect, useState } from 'react';
import Radio from '@mui/material/Radio';
import RadioGroup from '@mui/material/RadioGroup';
import FormControlLabel from '@mui/material/FormControlLabel';
import { useDispatch, useSelector } from 'react-redux';
import { setRadijus, setColor, setColorBackground, setSlikaLogo, setStyle, setOpisQrCode } from '../redux/stranice/odabir_vrste_qr';
import { RootState } from '../redux/store';
import { Button, Box, Container, TextField, Typography } from '@mui/material';
import { MuiColorInput } from 'mui-color-input'
import Upload from '../upload.png';
import None from '../slike_app/no-stopping.png';
import Qrcode1 from '../slike_app/qrcode1.png';


function StilizovanjeQR() {

  const dispatch = useDispatch();
  const color = useSelector((state: RootState) => state.odabirVrsteQR.color);
  //const colorBackground = useSelector((state: RootState) => state.odabirVrsteQR.colorBackground);
  const [slika, setSlika] = useState<any>("");
  const [slikaIme, setSlikaIme] = useState<any>("");
  const [opisniTextPrikaz, setOpisniTextPrikaz] = useState<any>(false);
  const [textPrikaz, setTextPrikaz] = useState<any>("");
  const opisQrCode = useSelector((state: RootState) => state.odabirVrsteQR.opisQrCode);

  const handleChangeColor = (color:any) => {
    dispatch(setColor(color)); 
  }

  // const handleChangeBackgroundColor = (color:any) => {
  //   dispatch(setColorBackground(color));
  // }

  const radijus = (e:number) => { 
    dispatch(setRadijus(e));
  }



  function handlefile(event:any){
    var reader = new FileReader();
    const uploadedFile = event.target.files[0];

    reader.readAsDataURL(event.target.files[0]);
    

    dispatch(setSlikaLogo(URL.createObjectURL(event.target.files[0]))); 
    setSlikaIme(uploadedFile.name);

    // reader.onload = () => {
    //       if (reader.result) {
    //         const data = (reader.result as string).replace("data:", "").replace(/^.+,/, "");
    //         setSlika(data);
    //         setSlikaIme(uploadedFile.name);
            
           

                                // fetch('http://localhost:5500/dodajSliku', {
                                //     method: 'POST', 
                                //     headers: {
                                //     'Content-Type': 'application/json'
                                //     },
                                //     body: JSON.stringify({
                                //         slika:data,
                                //         slikaIme:uploadedFile.name
                                //             })        
                                //         }).then(res => res.json())
                                //         .then(data => {
                                            
                                //             // console.log(data)
                                //             if(data.poruka == "uspesno"){
                                //               //dispatch(setSlikaLogo(`../../slike/${uploadedFile.name}` )); 
                                //                 //alert("Uspesno dodata slika");
                                //               } 
                                //         })

    //       } else {
    //         console.error('Greška pri čitanju datoteke.');
    //       }

    // };
    // reader.onerror = error => {
    //   console.log("Error: ", error);
    // }
    
  }


  function odabirIzgleda(izgled:number){
      if(izgled === 0){
        setOpisniTextPrikaz(false);
        dispatch(setStyle(0));
      }else if(izgled === 1){
        setOpisniTextPrikaz(true);
        dispatch(setStyle(1));
      }
    
  }



  const promenaOpisa = (event:any) => {
    dispatch(setOpisQrCode(event.target.value)); // Ažurirajte vrednost promenljive u Redux-u
  };


  return (
    <div>
      <h4>Radijus</h4>

                              <Box 
                                sx={{
                                  display: 'flex',
                                  // justifyContent: 'center',
                                  // alignItems: 'center',
                                  // flexDirection: 'column',
                                  margin:'auto',
                                  //height:'100vh',
                                  //backgroundColor:'red'
                                }}
                              >
                                  <Button  color="success" variant="contained"  onClick={() => radijus(0)}>0px</Button>&nbsp;
                                  <Button  color="success" variant="contained"  onClick={() => radijus(5)}>5px</Button>&nbsp;
                                  <Button  color="success" variant="contained"  onClick={() => radijus(10)}>10px</Button>&nbsp;
                                  <Button  color="success" variant="contained"  onClick={() => radijus(15)}>15px</Button>&nbsp;
                                  <Button  color="success" variant="contained"  onClick={() => radijus(20)}>20px</Button>&nbsp;
                                  <Button  color="success" variant="contained"  onClick={() => radijus(30)}>30px</Button>
                              </Box>

                              
                              <h4>Boja</h4>
                              <MuiColorInput value={color} fullWidth onChange={handleChangeColor} />
                              {/* <h3>Pozadina</h3>
                              <MuiColorInput value={colorBackground} fullWidth onChange={handleChangeBackgroundColor} /> */}

                             
                              <br/>
                              <br/>
                              <label htmlFor="fileInput">
                                {slika ? (
                                  <>
                                  <p>Izabrani fajl: {slikaIme}</p>
                                  {/* <img src={slika} alt="" style={{ maxWidth: '100%', maxHeight: '200px' }} /> */}
                                  </>
                                ) : (
                                  <>
                                  <img src={Upload} alt="Logo" style={{ width: '35px', height: '35px' }}/><span>Izaberite logo</span>
                                  
                                  </>
                                )}
                              </label>

                              <h4>Izgled</h4>
                              <img src={None} alt="None" style={{ width: '50px', height: '50px' }} onClick={() => odabirIzgleda(0)} />&nbsp;
                              <img src={Qrcode1} alt="None" style={{ width: '50px', height: '50px' }} onClick={() => odabirIzgleda(1)} />
                              <br/>
                              {opisniTextPrikaz ? 
                                <>
                                  <span>Text</span>
                                  <br/>
                                  <input type="text" /*value={opisQrCode}*/ onChange={promenaOpisa}/>
                                </>
                              : false }

                              <input type="file" accept="image/*" id="fileInput" name="file" onChange={handlefile} style={{ display: 'none' }}/>
                              {/* <img src={urlSlika} alt="" style={{ maxWidth: '100%', maxHeight: '200px' }}/> */}
                              <br/>
                             
                             

      
    </div>
  );
}

export default StilizovanjeQR;