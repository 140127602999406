import React, { useEffect, useState } from 'react';
import { QRCode } from 'react-qrcode-logo';
import { Button, Box, Container, TextField, Typography } from '@mui/material';

import Radio from '@mui/material/Radio';
import RadioGroup from '@mui/material/RadioGroup';
import FormControlLabel from '@mui/material/FormControlLabel';
import FormControl from '@mui/material/FormControl';
import FormLabel from '@mui/material/FormLabel';
import { useDispatch, useSelector } from 'react-redux';
import { setStranica, setQrCodeString } from '../../redux/stranice/odabir_vrste_qr';
import { RootState } from '../../redux/store';

import html2canvas from 'html2canvas';
import StilizovanjeQR from '../stilizovanje_qr';
import Teamplate from '../../teamplate/scan_me_qr_code.png';
import { position } from 'html2canvas/dist/types/css/property-descriptors/position';
import styles from '../../css/moj.module.scss';


import WifiIcon from '@mui/icons-material/Wifi';
import LanguageIcon from '@mui/icons-material/Language';
import SmsIcon from '@mui/icons-material/Sms';
import EmailIcon from '@mui/icons-material/Email';
import AddCardIcon from '@mui/icons-material/AddCard';
import StarBorderPurple500Icon from '@mui/icons-material/StarBorderPurple500';



// import Img from '../../20230830_122109.jpg';
// import Img1 from '../../slike/20230611_163309.jpg';

export const WiFiForm = (props:any) => {

  const dispatch = useDispatch();
  const [ime, setIme] = useState('');
  const [lozinka, setLozinka] = useState('');
  const [enkripcija, setEnkripcija] = useState('none');

  const handleChangeIme = (event:any) => {setIme(event.target.value);};
  const handleChangeLozinku = (event:any) => {setLozinka(event.target.value);};
  const handleRadioChange = (event:any) => {
    setEnkripcija(event.target.value);
  };

  //const [downloadUrl, setDownloadUrl] = useState<string | null>(null);// //zbog downloada






  
  const generisi = () => { 

    if(ime == ''){
      alert('Ime je obavezan podatak');
    }else{
        const wifiData = {
          ime: ime,
          lozinka: lozinka,
          enkripcija: enkripcija,
        };
        const wifiConfigURI = `WIFI:T:${wifiData.enkripcija};S:${wifiData.ime};P:${wifiData.lozinka};;`;
        
      dispatch(setQrCodeString(wifiConfigURI)); 

    }
      
  }

  

  return(
    <>
           

            <Box 
              maxWidth="xl"
              sx={{
                display: 'flex',
                 margin:'auto'
              }}
            >
                      <Box 
                      sx={{
                        display: 'flex',
                        justifyContent: 'center',
                        alignItems: 'center',
                        flexDirection: 'column',
                        margin:'auto'
                      }}
                      >



                  

                      
                              <TextField
                                  size="small"
                                  onChange={handleChangeIme}
                                  label="Ime"
                                  variant="outlined"
                                  value={ime}
                              />
                              <br/>
                              <br/>
                              <TextField
                                  size="small"
                                  onChange={handleChangeLozinku}
                                  label="Lozinka"
                                  variant="outlined"
                                  value={lozinka}
                              />


                              <RadioGroup
                                row
                                aria-labelledby="demo-row-radio-buttons-group-label"
                                name="row-radio-buttons-group"
                                value={enkripcija}
                                onChange={handleRadioChange}
                              >
                                  <FormControlLabel value="none" control={<Radio />} label="None" />
                                  <FormControlLabel value="WPA" control={<Radio />} label="WPA/WPA2" />
                                  <FormControlLabel value="WEP" control={<Radio />} label="WEP" />
                              </RadioGroup>
                              <br/>
                              <Button  color="success" variant="contained"  onClick={() => generisi()}>Generiši za WI FI</Button>

                      </Box>

            </Box>


          
        
                          
    
  </>
  
  )
}



export default WiFiForm;