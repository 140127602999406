import WiFiForm from './wi_fi_form';
import WebForm from './web_form';
import SmsForm from './sms_form';
import EmailForm from './email_form';
import VizitKartaForm from './vizit_karta_form';
import GoogleRecenzijaForm from './google_recenzija_form';

import { useDispatch, useSelector } from 'react-redux';
import { RootState } from '../../redux/store';
import {  useEffect } from "react";

import { setStranica } from '../../redux/stranice/odabir_vrste_qr';

export const Forme = () => {

   const formaStranica = useSelector((state: RootState) => state.odabirVrsteQR.formaStranica);
   const dispatch = useDispatch();

//OVO UVEK KADA SE DODJE NA STRANICU RESETUJE PRVO SVE PROMENLJIVE U REDUXU....PA POSLE NEK RADI KAKO HOCE
// const location = useLocation();
// useEffect(() => {
//   if (location.pathname === '/klijenti') {
//       dispatch(setStranica(1));  
//   }
// }, [location.pathname]);
//OVO UVEK KADA SE DODJE NA STRANICU RESETUJE PRVO SVE PROMENLJIVE U REDUXU....PA POSLE NEK RADI KAKO HOCE

 


 return(
   <>   
     {formaStranica === 1 && <WiFiForm/>}
     {formaStranica === 2 && <WebForm/>}
     {formaStranica === 3 && <SmsForm/>}
     {formaStranica === 4 && <EmailForm/>}
     {formaStranica === 5 && <VizitKartaForm/>}
     {formaStranica === 6 && <GoogleRecenzijaForm/>}

   </>
 )
};


export default Forme;