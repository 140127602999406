import React, { useEffect, useState } from 'react';
import { QRCode } from 'react-qrcode-logo';
import { Container, Grid, Button } from '@mui/material';
import { useDispatch, useSelector } from 'react-redux';
import { RootState } from '../redux/store';
import html2canvas from 'html2canvas';
import StilizovanjeQR from './stilizovanje_qr';
import styles from '../css/moj.module.scss';
import '../css/index.scss';
import Forme from './forme/forme';
import OdabirVrsteQR from './odabir_vrste_qr';

export const Glavna = (props:any) => {
  const qrCodeString = useSelector((state: RootState) => state.odabirVrsteQR.qrCodeString);
  const radijus = useSelector((state: RootState) => state.odabirVrsteQR.radijus);
  const color = useSelector((state: RootState) => state.odabirVrsteQR.color);
  const colorBackground = useSelector((state: RootState) => state.odabirVrsteQR.colorBackground);
  const slikaLogo = useSelector((state: RootState) => state.odabirVrsteQR.slikaLogo);
  const odabirStyle = useSelector((state: RootState) => state.odabirVrsteQR.style);
  const opisQrCode = useSelector((state: RootState) => state.odabirVrsteQR.opisQrCode);

  const [cont, setCont] = useState<any>(styles.container);
  const [box, setBox] = useState<any>(styles.box);
  const [scan_box, setScan_box] = useState<any>(styles.scan_box);
  const [withArrow, setWithArrow] = useState<any>(styles.withArrow);
  const [cont_z, setCont_z] = useState<any>(styles.container_z);
  const [box_z, setBox_z] = useState<any>(styles.box_z);
  const [scan_box_z, setScan_box_z] = useState<any>(styles.scan_box_z);
  const [withArrow_z, setWithArrow_z] = useState<any>(styles.withArrow_z);

  const [w, setW] = useState<any>(cont_z);
  const [w1, setW1] = useState<any>(box_z);
  const [w2, setW2] = useState<any>(scan_box_z);
  const [w3, setW3] = useState<any>(withArrow_z);

  const dispatch = useDispatch();

  const [downloadUrl, setDownloadUrl] = useState<string | null>(null);

  useEffect(() => {
    html2canvas(document.getElementById('qr-container') as HTMLElement).then(
      (canvas) => {
        const dataUrl = canvas.toDataURL('image/png');
        setDownloadUrl(dataUrl);
      }
    );
  }, [qrCodeString, radijus, color, slikaLogo, odabirStyle, opisQrCode]);

  useEffect(() => {
    if (odabirStyle === 0) {
      setW(cont_z);
      setW1(box_z);
      setW2(scan_box_z);
      setW3(withArrow_z); 
    } else if (odabirStyle === 1) {
      setW(cont);
      setW1(box);
      setW2(scan_box);
      setW3(withArrow);
    }
  }, [odabirStyle]);

  return (
    <Container maxWidth="xl" sx={{ p: 2 }}>
      <Grid container spacing={5}>
        <Grid item xs={12} md={6}>
          <OdabirVrsteQR />
          <br/>
          <Forme />
        </Grid>
        <Grid item xs={12} md={6}>
          <div id="qr-container" className={w}>
            <div className={w1}>
              <QRCode 
                value={qrCodeString}
                logoImage={slikaLogo} 
                logoPadding={3}   
                size={255} 
                logoWidth={180} 
                logoHeight={70}
                removeQrCodeBehindLogo={true}
                eyeRadius={[
                  radijus,  // top/left eye
                  radijus, // top/right eye
                  radijus,  // bottom/left eye
                ]}
                enableCORS={false}
                ecLevel={'H'} 
                fgColor={color}
                bgColor={colorBackground}  
              />
            </div>
            <div className={`${w2} ${w3}`}>
              &nbsp;{opisQrCode}&nbsp;
            </div>
          </div>
          <StilizovanjeQR />
          <br/>
          <a href={downloadUrl || ''} download="qrcode.png"><Button  color="error" variant="contained">Preuzmi QR kod</Button></a>
        </Grid>
      </Grid>
    </Container>
  );
}

export default Glavna;
