import React, { useEffect, useState } from 'react';
import QRCode from 'react-qr-code';
import qrcode from 'qrcode';
import { Button, Box, Container, TextField, Typography } from '@mui/material';
import WifiIcon from '@mui/icons-material/Wifi';
import LanguageIcon from '@mui/icons-material/Language';
import SmsIcon from '@mui/icons-material/Sms';
import EmailIcon from '@mui/icons-material/Email';
import AddCardIcon from '@mui/icons-material/AddCard';
import StarBorderPurple500Icon from '@mui/icons-material/StarBorderPurple500';
import '../css/index.scss';
import { setStranica, setFormaStranica } from '../redux/stranice/odabir_vrste_qr';
import { useDispatch, useSelector } from 'react-redux';

export const OdabirVrsteQR = (props:any) => {

  const dispatch = useDispatch();
  const [ime, setIme] = useState('');
  //const handleChangeIme = (event:any) => {setIme(event.target.value);};

  const odabirQRKoda = (vrsta:number) => { 
    dispatch(setFormaStranica(vrsta)); 
//alert(vrsta)
  }
  return(
    <>
         

           

        
            <div className='vrste_blok1'>
                            <div className='blokce1' onClick={() => odabirQRKoda(1)}>
                            
                            <WifiIcon/><br/><span>Wi-Fi</span>
                            </div>
                            <div className='blokce1' onClick={() => odabirQRKoda(2)}>
                            
                            <LanguageIcon/><br/><span>Web URL</span>
                            </div>
                            <div className='blokce1' onClick={() => odabirQRKoda(3)}>
                            
                            <SmsIcon/><br/><span>SMS</span>
                            </div>
                            <div className='blokce1' onClick={() => odabirQRKoda(4)}>
                            
                            <EmailIcon/><br/><span>Email</span>
                            </div>
                            <div className='blokce1' onClick={() => odabirQRKoda(5)}>
                            
                            <AddCardIcon/><br/><span>Vizit karta</span>
                            </div>
                            <div className='blokce1' onClick={() => odabirQRKoda(6)}>
                            
                            <StarBorderPurple500Icon/><br/><span>Google recenzije</span>
                            </div>
                

            </div>


    
  </>
  
  )
}



export default OdabirVrsteQR;