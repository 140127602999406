import React, { useEffect, useState } from 'react';

import { Button, Box, TextField } from '@mui/material';


import { useDispatch, useSelector } from 'react-redux';
import { setQrCodeString } from '../../redux/stranice/odabir_vrste_qr';




export const EmailForm = (props:any) => {

  const dispatch = useDispatch();
  const [email, setEmail] = useState('');
  const [naslov, setNaslov] = useState('');
  const [poruka, setPoruka] = useState('');
  const handleChangeEmail = (event:any) => {setEmail(event.target.value);};
  const handleChangeNaslov = (event:any) => {setNaslov(event.target.value);};
  const handleChangePoruka = (event:any) => {setPoruka(event.target.value);};

  const generisi = () => { 

    if(email == '' || naslov == '' || poruka == ''){
      alert('Popunite sva tri polja');
    }else{
        const webConfigURI = `mailto:${email}?subject=${encodeURIComponent(naslov)}&body=${encodeURIComponent(poruka)}`;      
        dispatch(setQrCodeString(webConfigURI)); 
    }      
  }


  return(
    <>
            
        <Box 
            style={{padding:"20px"}} 
            maxWidth="xl"
            sx={{
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center',
            flexDirection: 'column',
            margin:'auto'
            }}
        >

            <TextField
                size="small"
                fullWidth
                onChange={handleChangeEmail}
                label="Email"
                variant="outlined"
                value={email}
            />

            <br/>

            <TextField
                size="small"
                fullWidth
                onChange={handleChangeNaslov}
                label="Naslov"
                variant="outlined"
                value={naslov}
            />
            <br/>

            <TextField
                size="small"
                fullWidth
                onChange={handleChangePoruka}
                label="Poruka"
                variant="outlined"
                value={poruka}
            />
            <br/>
            <Button  color="success" variant="contained"  onClick={() => generisi()}>Generiši za Email</Button>
        
        </Box>
    </>
  
  )
}



export default EmailForm;